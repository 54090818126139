$color-peach-light: #facdbd;
$color-peach: #ee765e;
$color-peach-light: #facdbd;
$color-peach: #ee765e;
$color-peach10: #f5ded9;
$color-peach110: #dd6e58;
$color-peach-hover: #dd6e58;
$color-peach110: #dd6e58;
$color-peach-hover: #dd6e58;

$color-pink00: #fcf9f6;
$color-pink: #f6f0e8;
$color-pink00: #fcf9f6;
$color-pink: #f6f0e8;
$color-pink10: #f5ded9;

$color-gold-rose: #f5e8df;
$color-gold-white: #f8f5ef;
$color-gold: #f4e8cf;

$color-black: #222222;

$color-gray70: #585858;
$color-gray50: #c1c1c1;
$color-gray30: #dfdfdf;
$color-gray10: #efefef;

$color-green: rgb(205, 232, 231);
$color-green-dark: rgb(102, 192, 191);
$color-green-validation: #b0dbda;
$color-green-connexion: #66c1bf;

$color-orange-waiting: #fdb97b;

$color-green-validation: #b0dbda;
$color-green-connexion: #66c1bf;

$color-orange-waiting: #fdb97b;

$color-white: #ffffff;

//2023 Values
// $color-peach: #f37875;
// $color-peach10: #f5ded9;
// $color-peach110: #ec6a66;
// $color-peach-hover: #EC6A66;

// $color-pink: #F6F0E8;
// $color-pink10: #f5ded9;

// $color-gold-rose: #f5e8df;
// $color-gold-white: #f8f5ef;
// $color-gold: #f4e8cf;

// $color-black: #222222;

// $color-gray70: #585858;
// $color-gray50: #c1c1c1;
// $color-gray30: #dfdfdf;
// $color-gray10: #efefef;

// $color-white: #ffffff;

$shadow-default:
  0px 0px 1px rgba(0, 0, 0, 0.04),
  0px 4px 8px rgba(0, 0, 0, 0.04),
  0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.04);
$shadow-small:
  0px 0px 1px rgba(0, 0, 0, 0.04),
  0px 2px 6px rgba(0, 0, 0, 0.04),
  0px 10px 20px rgba(0, 0, 0, 0.04);
$shadow-drop: 0px 3px 4px rgba(0, 0, 0, 0.05);
$shadow-borderTop: 0px -1px 0px $color-gray10;
$shadow-borderBottom: inset 0px -1px 0px $color-gray10;

$gap: 0.8rem;

$font-default: 'futura-pt';
$font-display: 'Canela';
$font-fallback:
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Open Sans,
  Helvetica Neue,
  sans-serif;
$font-size: 1rem;

$radii-default: $gap;
$radii-small: calc($gap / 2);
$radii-xsmall: calc($gap / 4);

$transition-default: 0.2s ease-in-out;

$header-size-large: 12rem;
$header-size-medium: 8rem;
$header-size-small: 5.6rem;

$border-radius: 4px;

$min-width-screen-res: 1181px;
$max-width-screen-res: 1180px;
$max-width-screen-res--tablette: 767.99px;
$min-width-screen-res--tablette: 768px;

$agora-min-width-super-screen-desktop-res: 2900px;
$agora-min-width-screen-desktop-res: 1181px;
$agora-max-width-screen-tablet-res: 1180px;
$agora-min-width-screen-tablet-res: 768px;
$agora-max-width-screen-mobile-res: 767.99px;

$csrheight: 0rem;
$menuHeightValue: 56px;

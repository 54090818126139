/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
// @import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';

// @import '~ngx-toastr/toastr';

// @import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
// @import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

@import 'index';

// @import "swiper/swiper-bundle.css";

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  font-family: 'futura-pt', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: $font-default, $font-fallback;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

app-flow-error {
  display: flex;
  align-self: flex-start;
}

p,
h1,
h2,
h3,
h4,
h5,
span,
div,
label {
  color: $color-black;
}

a {
  text-decoration: none;
  color: $color-black;
  cursor: pointer;
}

a {
  background-color: transparent;
}

b {
  color: $color-peach;
}

svg {
  outline: 0;
}

.link-wave {
  position: relative;
  text-transform: uppercase;
  font-size: 1.15rem !important;
  transition: all 150ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &:hover {
    color: $color-peach;

    div {
      svg {
        padding-left: 8px;
        width: 28px;
      }
    }
  }

  div {
    width: 100%;

    svg {
      padding: 5px;
      margin-right: -3px;
      font-size: 0.5rem;
      width: 25px;
      transition: all 150ms ease-in-out;
    }
  }

  svg.line {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc($gap / 2);
    color: $color-peach;
  }
}

// Default Icon Size
.icon {
  width: 20px;

  &.peach,
  .peach svg {
    color: $color-peach;
  }

  &.black,
  .black svg {
    color: $color-black;
  }

  &.white,
  .white svg {
    color: $color-white;
  }

  &.rounded {
    width: 35px;
    height: 35px;
  }
}

//  Margin When icon on the left of the content
.icon-left {
  margin-right: 8px;
}

// Margin When icon on the right of the content
.icon-right {
  margin-left: 8px;
}

.icon-menu {
  margin-right: 25px;
}

.icon-xl {
  width: 4rem;

  @media (max-width: $max-width-screen-res) {
    width: 100px;
  }
}

.m-icon-xl {
  @media (max-width: $max-width-screen-res) {
    width: 30px;
  }
}

html {
  // //ScrollBar FF
  // scrollbar-color:$color-pink  $color-white;
  // scrollbar-width: thin;
  // //ScrollBar Chrome Edge
  // &::-webkit-scrollbar, &::-webkit-scrollbar-thumb {
  //     width: 0.5rem;
  //     background: $color-pink;

  // }

  // &::-webkit-scrollbar-track{
  //     background: $color-white;
  // }
}

.fit {
  width: fit-content;
}

.separator {
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px !important;
}

.no-data-title {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 1.8rem !important;
  }
}

.background-pink {
  background: $color-pink !important;
}

.form-error-container {
  font-size: 1.3rem;
  color: $color-peach;

  div div {
    color: $color-peach;
  }

  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 1.5rem !important;
  }
}

.cursor {
  cursor: pointer !important;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.display {
  display: block !important;
}

.error-box {
  background-color: $color-pink10;
  padding: 1.2rem 1.6rem;
  margin: 3rem 0;
  border-radius: $border-radius;

  @media (max-width: $max-width-screen-res--tablette) {
    margin: 1rem 0 3rem;
  }

  .icon-warning {
    width: 2.6rem;
    color: $color-peach;
    margin-right: 1.5rem;
  }

  span {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    color: $color-peach;

    @media (max-width: $max-width-screen-res--tablette) {
      font-size: 1.4rem;
    }
  }
}

.error-margin-top {
  margin-top: 2rem !important;
}

.error-frame-container {
  &.withFrame {
    padding: calc($gap * 3) calc($gap * 6);
    background: $color-white;
    border-radius: $radii-default;
    box-shadow: $shadow-small;
    min-height: 500px;

    @media (max-width: 768px) {
      padding: calc($gap * 4) calc($gap * 3);
    }
  }

  .header {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3rem;
    letter-spacing: -0.01em;
  }

  h1 {
    font-size: 3rem;
    line-height: 3.4rem;
    margin: 0;
    font-weight: 400;
    letter-spacing: -0.01em;

    @media (max-width: 768px) {
      margin-top: 0 !important;
    }
  }

  .subtitle {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 500;
  }

  img {
    width: 45%;
    max-width: 100px;
    margin: 25px;
  }

  a.ctaLink {
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.07em;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 0 calc($gap / 2) 0;
    border-bottom: 1px solid $color-gray30;
    color: $color-black;
    transition:
      border-color $transition-default,
      color $transition-default;
    background-color: transparent;

    &:hover {
      color: $color-peach;
      border-color: $color-peach;
    }
  }
}

//LOADER
.loader-container {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 60vh;
  padding: 2.4rem 4.8rem;
  background: #ffffff;
  border-radius: 0.8rem;
  box-shadow:
    0px 0px 1px rgb(0 0 0 / 4%),
    0px 2px 6px rgb(0 0 0 / 4%),
    0px 10px 20px rgb(0 0 0 / 4%);

  &.no-box {
    box-shadow: none;
  }
}

.loader {
  border: 16px solid $color-pink;
  border-top: 16px solid $color-peach;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-medium {
  width: 60px;
  height: 60px;
  border: 8px solid $color-pink;
  border-top: 8px solid $color-peach;
  margin-right: 1.5rem;
}

.loader-mini {
  width: 20px;
  height: 20px;
  border: 3px solid $color-pink;
  border-top: 3px solid $color-peach;
  margin-right: 1.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//LOADER

//HEART ANIMATION
.heart-animated {
  animation: heartbeat 1.4s linear;
}

@keyframes heartbeat {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 1;
    transform: scale(1);
  }

  85% {
    transform: scale(1.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

//ALERTS
.alert-box {
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 0px;
  transition: all $transition-default;
  gap: 5px;
  padding: calc($gap * 2);

  @media (min-width: $max-width-screen-res) {
    width: 400px;
  }

  &.top {
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: $max-width-screen-res) {
      left: auto;
      width: 400px;
    }
  }

  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;

    @media (min-width: $max-width-screen-res) {
      left: auto;
    }
  }

  &.success {
    color: $color-peach;
    background-color: $color-peach10;
  }

  &.danger {
    color: $color-peach;
    background-color: $color-peach10;
  }

  &.warning {
    color: $color-peach;
    background-color: $color-peach10;
  }

  &.info {
    color: $color-white;
    background-color: #585858;
  }

  &.visible {
    z-index: 9999999;
    opacity: 1;
    height: 70px;
  }

  svg {
    width: 25px;
  }

  a {
    color: currentColor;
  }
}

//LOADER BALLS PULSE
//   <div class="loader-bullet">
//     <div></div>
//     <div></div>
//     <div></div>
//   </div>
.loader-bullet,
.loader-bullet > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.loader-bullet {
  display: block;
  font-size: 0;
  color: $color-peach;
}

.loader-bullet.la-dark {
  color: #333;
}

.loader-bullet > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  color: $color-peach;
}

.loader-bullet {
  width: 70px;
  height: 18px;
  color: $color-peach;
}

.loader-bullet > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms;
}

.loader-bullet > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms;
}

.loader-bullet > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
}

.loader-bullet > div {
  width: 15px;
  height: 15px;
  margin: 4px;
  border-radius: 100%;
  -webkit-animation: ball-pulse 1s ease infinite;
  -moz-animation: ball-pulse 1s ease infinite;
  -o-animation: ball-pulse 1s ease infinite;
  animation: ball-pulse 1s ease infinite;
}

.loader-bullet.la-sm {
  width: 26px;
  height: 8px;
}

.loader-bullet.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}

.loader-bullet.la-2x {
  width: 108px;
  height: 36px;
}

.loader-bullet.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.loader-bullet.la-3x {
  width: 162px;
  height: 54px;
}

.loader-bullet.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@-moz-keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  30% {
    opacity: 0.1;
    -moz-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@-o-keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  30% {
    opacity: 0.1;
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    -moz-transform: scale(0.01);
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}

//END LOADER BULLETs

//TABLE LOADER

.table-loader {
  position: absolute;
  left: -3rem;
  top: -0.5rem;
  width: 105%;
  height: calc(100% + 1rem);
  background-color: rgba(0, 0, 0, 0.05);
  min-height: 60px;
  border-radius: $radii-default;
}

//END TABLE LOADER

//ALERTS
.alert-box {
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 0px;
  transition: all $transition-default;
  gap: 5px;
  padding: calc($gap * 2);

  @media (min-width: $max-width-screen-res) {
    width: 400px;
  }

  &.top {
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: $max-width-screen-res) {
      left: auto;
      width: 400px;
    }
  }

  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;

    @media (min-width: $max-width-screen-res) {
      left: auto;
    }
  }

  &.success {
    color: $color-peach;
    background-color: $color-peach10;
  }

  &.danger {
    color: $color-peach;
    background-color: $color-peach10;
  }

  &.warning {
    color: $color-peach;
    background-color: $color-peach10;
  }

  &.info {
    color: $color-white;
    background-color: #585858;
  }

  &.visible {
    z-index: 9999999;
    opacity: 1;
    height: 70px;
  }

  svg {
    width: 25px;
  }

  a {
    color: currentColor;
  }
}

//ALERTS
.alert-box {
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 0px;
  transition: all $transition-default;
  gap: 5px;
  padding: calc($gap * 2);

  @media (min-width: $max-width-screen-res) {
    width: 400px;
  }

  &.top {
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: $max-width-screen-res) {
      left: auto;
      width: 400px;
    }
  }

  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;

    @media (min-width: $max-width-screen-res) {
      left: auto;
    }
  }

  &.success {
    color: $color-black;
    background-color: #6dd1b3;

    a,
    svg,
    p {
      color: $color-black;
    }
  }

  &.danger {
    color: $color-peach;
    background-color: $color-peach10;

    a,
    svg,
    p {
      color: $color-peach;
    }
  }

  &.warning {
    color: $color-peach;
    background-color: $color-peach10;

    a,
    svg,
    p {
      color: $color-peach;
    }
  }

  &.info {
    color: $color-white;
    background-color: #585858;

    a,
    svg,
    p {
      color: $color-white;
    }
  }

  &.visible {
    z-index: 9999999;
    opacity: 1;
    height: 70px;
  }

  svg {
    width: 25px;
  }

  a {
    color: currentColor;
  }
}

//TOASTR OVERRIDE
.toast-close-button {
  text-shadow: none !important;

  span {
    color: white !important;
  }
}

.toast-container {
  z-index: 9999999999999 !important;
  .toast-info {
    div {
      color: $color-white !important;
    }
    background-color: #585858;

    a,
    svg,
    p {
      color: $color-white;
    }
  }

  .toast-error {
    div {
      color: $color-peach !important;
    }
    background-color: $color-peach10;

    .toast-close-button {
      span {
        color: $color-white !important;
      }
    }

    a,
    svg,
    p {
      color: $color-peach;
    }
  }

  .toast-warning {
    div {
      color: $color-peach !important;
    }
    background-color: $color-peach10;

    .toast-close-button {
      span {
        color: $color-white !important;
      }
    }

    a,
    svg,
    p {
      color: $color-peach;
    }
  }

  .toast-success {
    div {
      color: $color-black !important;
    }
    background-color: #6dd1b3;

    .toast-close-button {
      span {
        color: $color-white !important;
      }
    }

    a,
    svg,
    p {
      color: $color-black;
    }
  }
}

@media (prefers-color-scheme: dark) {
  option {
    background: #333 !important;
    color: white !important;
  }

  .select-container {
    .list {
      .list-items,
      .item {
        background: #333 !important;
        .item-description {
          color: white !important;
        }
      }

      .item:hover {
        background: grey !important;
      }
    }
  }
}

.promo-price {
  color: $color-peach !important;
}

.old-price {
  color: rgb(193, 193, 193) !important;
  text-decoration: line-through !important;
  margin-left: 1rem;
}

app-connect-as {
  display: flex;
  justify-content: center;
}

.connect-as-body {
  margin: 2rem auto auto auto;
}

app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/******* LOADER BLOCK FOR MAIN BODY ********/
.loader-block {
  min-height: 65vh;
}

.d-dashboard-grid {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 32px !important;
    grid-row-gap: 32px !important;
  }
}

//hide scrollbar
.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
  width: 0;
}

@import 'fonts';
@import 'variables';
@import 'form';
@import 'buttons';
@import 'block';
@import 'flex';
@import 'table';

.center-section {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: calc($gap * 168);
  transition: all 750ms ease-in-out;
  padding: 0 5%;

  @media (max-width: $max-width-screen-res) {
    padding: 0 15px;
  }
}

.magnify {
  cursor: zoom-in;
}

@import 'variables';

// Canela
@font-face {
  font-family: 'canela';
  // src: url('/assets/fonts/canela/Canela-Thin.woff2') format('woff2');
  src: url('/assets/fonts/Garamond/Adobe Garamond Pro Regular.ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//         font-family: 'futura-pt';
//         src: url('/assets/fonts/FuturaPT/FuturaPT-Book.woff2') format('woff2'),
//             url('/assets/fonts/FuturaPT/FuturaPT-Book.woff') format('woff');
//         font-weight: normal;
//         font-style: normal;
//         font-display: swap;
//     }

.hand {
  cursor: pointer;
}

.canela {
  font-family: 'garamond-premier-pro', serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: -0.48px;
  font-family: 'garamond-premier-pro', serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: -0.48px;
}

.futura {
  font-family: 'futura-pt', sans-serif;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.m-center {
  @media (max-width: $min-width-screen-res) {
    text-align: center;
  }
}

.justify {
  text-align: justify;
}

.normal {
  font-weight: normal;
}

.light {
  font-weight: lighter;
}

.lightbold {
  font-weight: 500 !important;
}

/*.bold{
  font-weight:bold;
}*/

.bolder {
  font-weight: bolder;
}

.title {
  font-size: 4.8rem;
  line-height: 5.4rem;
  letter-spacing: -0.48px;
  font-size: 4.8rem;
  line-height: 5.4rem;
  letter-spacing: -0.48px;
  margin-top: 0;
  &.withSub {
    margin-bottom: 10px;
  }

  @media (max-width: $max-width-screen-res) {
    font-size: 3rem;
    line-height: 3rem;
    &.withSub {
      margin-bottom: 15px;
    }
  }
}

.padding-10 {
  padding: 1rem;
}

.padding-left-5 {
  padding-left: 5px;
}

.title-sm {
  font-size: 1.8rem;
  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 2rem !important;
  }
}

.title-m {
  font-size: 2.1rem;
  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 2.3rem !important;
  }
}
.title-l {
  font-size: 2.35rem;
  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 2.55rem !important;
  }
}

.title-section {
  font-family: 'futura-pt';
  padding-top: 25px;
  padding-bottom: 15px;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  border-bottom: 3px solid #dddddd;
  &.inverted {
    border-bottom: 3px solid white;
  }

  @media (max-width: $max-width-screen-res) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 1.95rem !important;
  }
}

.text-margin {
  margin: 20px 0px;
}

.text-margin-top {
  margin-top: 10px;
}

.text-margin-bottom {
  margin-bottom: 10px;
}

.no-maring-bottom {
  margin-bottom: 0 !important;
}

.d-no-maring-bottom {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    margin-bottom: 0 !important;
  }
}

.text-margin-1 {
  margin: 10px 0px;
}

.text-margin-2 {
  margin: 20px 0px;
}

.text-margin-3 {
  margin: 30px 0px;
}

.margin-top-15 {
  margin-top: 1.5rem;
}

.margin-top-20 {
  margin-top: 2rem;
}

.margin-top-25 {
  margin-top: 2.5rem;
}

.margin-top-30 {
  margin-top: 3rem;
}

h1 {
  margin-top: 15px !important;
  font-size: 2.5rem;
  @media (max-width: $max-width-screen-res) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 1.95rem !important;
  }
}

.uppercase {
  text-transform: uppercase;
}

.no-line-height {
  line-height: 1 !important;
}

.line-height-zero {
  line-height: 0 !important;
}

.menu-title {
  font-size: 1.8rem;
}

.pointer {
  cursor: pointer;
}

.margin-auto {
  margin: auto;
}

.margin-top-50 {
  margin-top: 5rem !important;
}

.link-cgv {
  a {
    text-decoration: underline;
  }
}

h2 {
  color: $color-black;
}

h1.canela {
  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 3.4rem !important;
  }
}

.font-XXL {
  font-size: 2rem;
}

.font-size-2 {
  font-size: 2rem;
}

.d-font-size-2 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    font-size: 2rem;
  }
}

.page-container {
  padding: 10px 20px;
}

.block {
  display: block;
}

.m-fit-content {
  @media (max-width: $max-width-screen-res) {
    width: fit-content;
  }
}

.gap-5 {
  gap: 5px;
}

.gap-6 {
  gap: 6px;
}

.m-gap-4 {
  @media (max-width: $agora-min-width-screen-desktop-res) {
    gap: 4px !important;
  }
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.d-gap-10 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    gap: 10px;
  }
}

.gap-12 {
  gap: 12px;
}

.m-gap-12 {
  @media (max-width: $agora-min-width-screen-desktop-res) {
    gap: 12px !important;
  }
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px;
}

.d-gap-15 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    gap: 15px;
  }
}

.gap-20 {
  gap: 20px;
}

.m-gap-20 {
  @media (max-width: $agora-min-width-screen-desktop-res) {
    gap: 20px !important;
  }
}

.m-gap-initial {
  @media (max-width: $agora-min-width-screen-desktop-res) {
    gap: initial !important;
  }
}

.gap-24 {
  gap: 24px;
}

.m-gap-24 {
  @media (max-width: $agora-min-width-screen-desktop-res) {
    gap: 24px !important;
  }
}

.d-gap-20 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    gap: 20px;
  }
}

.gap-30 {
  gap: 30px;
}

.gap-32 {
  gap: 32px;
}

.d-gap-30 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    gap: 30px;
  }
}

.d-gap-32 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    gap: 32px;
  }
}

.gap-40 {
  gap: 40px;
}

.d-gap-40 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    gap: 40px;
  }
}

.gap-50 {
  gap: 50px;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.column {
  flex-direction: column;
}

.m-column {
  @media (max-width: $max-width-screen-res) {
    flex-direction: column !important;
  }
}

.row {
  flex-direction: row;
}

.m-row {
  @media (max-width: $max-width-screen-res) {
    flex-direction: row !important;
  }
}

.d-row {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    flex-direction: row;
  }
}

.nowrap {
  flex-wrap: nowrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.space-nowrap {
  white-space: nowrap;
}

.order2 {
  order: 2;
}

.d-order-2 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    order: 2;
  }
}

.wrap {
  flex-wrap: wrap;
}

.j-space-between {
  justify-content: space-between;
}

.m-j-space-between {
  @media (max-width: $max-width-screen-res) {
    justify-content: space-between !important;
  }
}

.j-space-around {
  justify-content: space-around;
}

.m-j-space-around {
  @media (max-width: $max-width-screen-res) {
    justify-content: space-around !important;
  }
}

.c-j-space-around {
  @media (max-width: 1350px) {
    justify-content: space-around !important;
  }
}

.j-center {
  justify-content: center;
}

.m-j-center {
  @media (max-width: $max-width-screen-res) {
    justify-content: center !important;
  }
}

.d-j-start {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    justify-content: flex-start;
  }
}

.j-start {
  justify-content: flex-start;
}

.m-j-start {
  @media (max-width: $max-width-screen-res) {
    justify-content: flex-start !important;
  }
}

.d-j-start {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    justify-content: flex-start !important;
  }
}

.j-end {
  justify-content: flex-end;
}

.m-j-end {
  @media (max-width: $max-width-screen-res) {
    justify-content: flex-end !important;
  }
}

.ai-baseline {
  align-items: baseline;
}

.ai-start {
  align-items: flex-start;
}

.m-ai-start {
  @media (max-width: $max-width-screen-res) {
    align-items: flex-start !important;
  }
}

.d-ai-start {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    align-items: flex-start;
  }
}

.ai-center {
  align-items: center;
}

.m-ai-center {
  @media (max-width: $max-width-screen-res) {
    align-items: center !important;
  }
}

.ai-end {
  align-items: flex-end;
}

.m-ai-end {
  @media (max-width: $max-width-screen-res) {
    align-items: end !important;
  }
}

.ai-stretch {
  align-items: stretch;
}

.m-ai-stretch {
  @media (max-width: $max-width-screen-res) {
    align-items: stretch !important;
  }
}

.ac-start {
  align-content: flex-start;
}

.m-ac-start {
  @media (max-width: $max-width-screen-res) {
    align-content: flex-start !important;
  }
}

.ac-center {
  align-content: center;
}

.m-ac-center {
  @media (max-width: $max-width-screen-res) {
    align-content: center !important;
  }
}

.ac-end {
  align-content: flex-end;
}

.m-ac-end {
  @media (max-width: $max-width-screen-res) {
    align-content: flex-end !important;
  }
}

.d-center-all {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}

.center-all {
  align-items: center;
  align-content: center;
  justify-content: center;
}

.vertical-align {
  @media (min-width: $min-width-screen-res) {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.no-margin-top {
  margin-top: 0;
}

.width-auto {
  width: auto;
}

.d-width-auto {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    width: auto;
  }
}

.width-fit {
  width: fit-content;
}

.d-width-fit {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    width: fit-content;
  }
}

.w100 {
  width: 100%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}

.grow-1 {
  flex-grow: 1;
}

.basis-2 {
  flex-basis: calc(50% - 20px);
}

.d-grow-2 {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    flex-grow: 2;
    flex-shrink: 2;
  }
}

.d-no-wrap {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    text-wrap: nowrap;
  }
}

.pink-area {
  background: $color-pink;
  padding: 25px 50px;
  border-radius: calc($border-radius * 2);
  margin-bottom: 50px;

  @media (max-width: $max-width-screen-res) {
    padding: 25px 20px;
    margin-bottom: 50px;
  }

  h1 {
    border-bottom: 3px solid $color-white;
    padding-bottom: 15px;
    font-weight: 500;
  }
}

.block-margin {
  margin: 20px 0px;
}

.block-margin-top {
  margin-top: 10px;
}

.block-margin-bottom {
  margin-bottom: 10px;
}

.m-no-margin {
  @media (max-width: $max-width-screen-res) {
    margin: 0 !important;
  }
}

.d-no-margin {
  @media (min-width: $agora-min-width-screen-desktop-res) {
    margin: 0 !important;
  }
}

.m-auto-no-margin {
  margin: 0 auto;
}

.m-no-margin-left {
  margin-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.fullwidth {
  position: relative;
  background: $color-pink;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 50px;
  padding-bottom: 40px;
}

.m-fullwidth {
  @media (max-width: $max-width-screen-res) {
    position: relative;
    background: $color-pink;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 50px;
    padding-bottom: 40px;
    border-radius: 0;
  }
}

.d-only {
  @media (max-width: $max-width-screen-res), (min-width: $agora-min-width-screen-tablet-res) and (max-width: $agora-max-width-screen-tablet-res) and (orientation: portrait) {
    display: none !important;
  }

  @media (min-width: $agora-min-width-screen-tablet-res) and (max-width: $agora-max-width-screen-tablet-res) and (orientation: landscape) {
    display: flex !important;
  }
}

.d-only-shop {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.m-only {
  @media (min-width: $min-width-screen-res), (min-width: $agora-min-width-screen-tablet-res) and (max-width: $agora-max-width-screen-tablet-res) and (orientation: landscape) {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}

.block-menu {
  display: block !important;
}

.fullwidth-only {
  width: 100%;
}

.height-auto {
  height: auto !important;
}

.ios-padding {
  padding-bottom: 6.8rem;
}

.rm-margin-top-5 {
  margin-top: -5px !important;
}

.rm-margin-top-10 {
  margin-top: -10px !important;
}

.rm-margin-top-15 {
  margin-top: -15px !important;
}

.rm-margin-top-20 {
  margin-top: -20px !important;
}

.m-rm-margin-top-20 {
  @media (max-width: $agora-min-width-screen-tablet-res) {
    margin-top: -20px !important;
  }
}

.rm-margin-top-25 {
  margin-top: -25px !important;
}

.rm-margin-top-30 {
  margin-top: -30px !important;
}

.d-rm-margin-top-30 {
  @media (min-width: $agora-min-width-screen-tablet-res) {
    margin-top: -30px !important;
  }
}

.rm-margin-top-50 {
  margin-top: -50px !important;
}

.rm-margin-top-75 {
  margin-top: -75px !important;
}

.d-rm-margin-top-75 {
  @media (min-width: $agora-min-width-screen-tablet-res) {
    margin-top: -75px !important;
  }
}

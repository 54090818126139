@import 'variables';

.table-title {
  position: relative;
  font-family: 'futura-pt';
  padding-top: 25px;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  border-bottom: 3px solid $color-gray10;
  padding-bottom: 1.5rem;
  margin-bottom: 3rem;
  color: $color-black;

  &.table-filter {
    margin-bottom: 1rem;
  }

  @media (max-width: $max-width-screen-res) {
    flex: 1;
    width: 100%;
    font-size: 1.8rem;
    font-weight: 450;
    line-height: 2.3rem;
    margin-bottom: 2rem;
    p {
      margin: 40px 10px 10px 10px;
    }
  }
  @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
    font-size: 2rem !important;
  }
}

.filter {
  padding-bottom: 30px;
  @media (min-width: $min-width-screen-res) {
    display: flex;
  }

  .filter-option {
    gap: 0 !important;
  }

  .checkbox-input {
    margin-right: 10px;
  }

  .filter-title {
    padding-right: 6rem;
    @media (max-width: $max-width-screen-res) {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
      p {
        margin: 0;
      }
    }
    @media (max-width: $max-width-screen-res) and (min-width: $min-width-screen-res--tablette) {
      font-size: 1.8rem !important;
    }
    p {
      color: $color-black;
    }
  }

  .switch {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.data-table {
  width: 100%;

  th {
    text-align: left;
    text-transform: uppercase;
    font-weight: 500;
    color: $color-black;
  }

  tr {
    td {
      height: 100%;
      padding-left: 10px;
      color: $color-black;

      p {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }
    }
  }
}

.div-full-height {
  tr {
    height: 60px;

    div {
      height: 100%;
    }
  }
}

.bottom-border {
  tr {
    border-bottom: $color-gray10 1px solid;
  }
}

.all-border {
  td {
    border-top: $color-gray10 2px solid;
    border-bottom: $color-gray10 2px solid;
  }

  td:first-child {
    border-left: 2px solid $color-gray10;
  }

  td:last-child {
    border-right: 2px solid $color-gray10;
  }
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
  border-spacing: 0px 30px;
}

.border-radius {
  tr td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  tr td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.td-bold {
  font-weight: 420;
  font-size: $font-size * 1.8;
}

.td-peach {
  color: $color-peach;
}

.div-text-bottom {
  display: flex;
  height: 100%;

  .bottom {
    display: inline-block;
    align-self: flex-end;
  }
}

.card-advantages {
  color: $color-peach;
  font-size: $font-size * 2.4;
  font-weight: 401;
}

.more {
  display: flex;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 50px;
  padding-top: 30px;
  &.no-padding-top {
    padding-top: 0;
  }

  div {
    display: grid;
    margin: 0 auto;
    cursor: pointer;
  }

  p {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 16px;
    border-bottom: solid 1px $color-gray10;
    margin-bottom: 5px;
  }

  svg {
    width: 22px;
    transform: rotate(90deg);
    margin: 0 auto;
  }

  .loading {
    cursor: wait !important;
    min-height: unset !important;
  }
}

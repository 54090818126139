@import 'variables';

.btn {
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-transform: uppercase;
  color: $color-black;
  background-color: $color-white;
  border: none;
  padding: 10px 30px;
  letter-spacing: 0.07rem;
  cursor: pointer;
  @media (min-width: $min-width-screen-res) {
    height: 45px;
  }
  @media (max-width: $max-width-screen-res) {
    height: 48px !important;
    font-size: 1.2rem !important;
    padding: 1.65rem 3rem !important;
    letter-spacing: 0.07rem;
  }
  font-weight: 600 !important;
}

.btn-icon {
  height: 45px;
  width: 45px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-transform: uppercase;
  color: $color-black;
  background-color: $color-white;
  padding: 0;
  border: none;
  cursor: pointer;
  @media (max-width: $max-width-screen-res) {
    height: 32px;
    width: 32px;
    padding: 0;
  }
}

.sm-btn-icon {
  height: 35px;
  width: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-transform: uppercase;
  color: $color-black;
  background-color: $color-white;
  cursor: pointer;
  border: 1px solid #efefef;
  box-shadow: 0px 3px 4px rgba(34, 34, 34, 0.05);
  border-radius: $border-radius;
}

.btn-primary {
  background-color: $color-peach;
  color: $color-white;
  transition: all $transition-default;

  &:hover {
    background-color: $color-peach-hover;
  }
  font-weight: 600 !important;
  @media (min-width: $min-width-screen-res) {
    font-size: 1.2rem;
  }

  &.secondary {
    background-color: $color-white;
    border: 1px solid $color-peach;
    color: $color-peach;
  }

  &.disabled {
    cursor: unset;
    background-color: rgba(243, 120, 117, 0.36);
    &:hover {
      background-color: rgba(243, 120, 117, 0.36);
    }
    &.secondary {
      background-color: $color-white;
    }
  }
}

.btn-block {
  width: 100%;
}

.m-btn-block {
  @media (max-width: $max-width-screen-res) {
    width: 100% !important;
  }
}

// Special button
.btn-menu {
  height: 65px;
  text-transform: capitalize;
  border-radius: calc($border-radius * 2) calc($border-radius * 2) 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12.5px 40px;
  font-size: 2.25rem;
  font-weight: 400;
  width: 255px;

  svg {
    transform: rotate(90deg);
  }
}

// Special button
.btn-back {
  border-color: $color-peach;
  border-width: 1px;
  border-style: solid;
  font-size: 1rem;
  height: 35px;
  font-style: normal;
  font-weight: 450;
}

.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid $color-gray30;

  &.onpeach {
    border: 1px solid white;
  }
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: $color-gray30;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.onpeach:before {
  background-color: white;
}

input:checked + .slider {
}

input:checked + .slider:before {
  background-color: $color-peach;
}

input:checked + .onpeach:before {
  background-color: $color-peach-light;
}

input:focus + .slider {
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wait-cursor {
  cursor: wait;
}

.btn-cta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 3rem;
  background-color: $color-peach;
  border-radius: $border-radius;
  align-content: center;
  text-transform: uppercase;
  color: $color-black;
  border: none;
  letter-spacing: 0.07rem;
  cursor: pointer;
  height: 4.8rem;
}
.m-btn-cta {
  height: 4.8rem;
  min-width: 4.8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: $color-peach;
  border-radius: $border-radius;

  &:disabled {
    background-color: $color-gray30;
  }

  &.btn-icon {
    height: 3.8rem;
    min-width: 3.8rem;
    min-height: 3.8rem;
  }

  & svg {
    width: 2rem;
    height: 2rem;
    color: white;
  }
  &.outline {
    background-color: white;
    border: solid 1px $color-peach;
    svg {
      color: $color-peach;
    }
    label {
      text-transform: uppercase;
      color: $color-peach;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }
  label {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: white;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
